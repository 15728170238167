body{
  background: linear-gradient(0deg, #13243C, #13243C),
linear-gradient(0deg, #226086, #226086);
} 
@font-face {
  font-family: 'open_sans_condensedbold';
  src: url('../public/fonts/opensans-condbold-webfont.woff2') format('woff2'), 
       url('../public/fonts/opensans-condbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'Sedgwick Ave Display';
  src: url('../public/fonts/SedgwickAveDisplay-Regular.woff2') format('woff2'), 
       url('../public/fonts/SedgwickAveDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'Poppins Regular';
  src: url('../public/fonts/Poppins-Regular.woff2') format('woff2'), 
       url('../public/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
/* heading */
h1{ 
  font-family: 'open_sans_condensedbold';
  color: #226086;
font-size: 200px;
font-weight: 700;
line-height: 207px;
letter-spacing: 0em;
text-align: left; 
text-transform: uppercase;
}
h2{
  font-family: 'open_sans_condensedbold';
font-size: 160px;
font-weight: 700;
line-height: 142px;
letter-spacing: -0.01em;
text-align: right;
color: #FFFFFFA6;
}
h4{
  font-size: 80px;
  color: #FFFFFFA6;
  font-family: 'Poppins Regular';
}
h5{
  color: #FFF;
  font-family: 'Poppins Regular';
}
.custf {
  font-family: 'open_sans_condensedbold';
  font-size: 160px;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 120px;
  -ms-transform: rotate(-90deg);
  transform: rotate(180deg);
  margin: 0px;
  writing-mode: vertical-lr;
  color: #FFFFFFA6;
}
.technicalseo {
  font-family: 'Sedgwick Ave Display';
  font-weight: normal;
  font-style: normal;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
  color:#fff;
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1.8rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #B3BCC9;
  border-radius: 50px;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #226086;
  transition: width .6s ease;
}
.tutorialbox {
  position: relative;  
  color:#fff;
}
.tutorial-left {
  position: absolute;
  top: 8px;
  left: 16px;
  padding: 30px;
}
.tutorial-right {
  position: absolute;
  top: 30%;
  right: 5px;
  text-align: center;
}
.fixed-top{
  border-bottom: 1px solid #FFFFFF;
}
.tutorial p {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}
.css-1o0wxxh >.ps-menu-button:hover {
  background-color: #D05340 !important;
}
.btn-white{
padding: 12px 24px 12px 24px;
border-radius: 8px;
border: 2px solid #fff;
gap: 10px;
color:#fff;
margin-top: 20px;
}
.btn-white:hover{
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #fff;
  gap: 10px;
  margin-top: 20px;
  color:#226086;
  }
  .btn-orange{
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    border: 2px solid #D05340;
    gap: 10px;
    color:#fff;
    background-color: #D05340;
    margin-top: 20px;
    }
    .btn-orange:hover{
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      border: 2px solid #fff;
      gap: 10px;
      color:#D05340;
      background-color: #fff;
      margin-top: 20px;
      text-decoration: none;
      }
      .navbelowmg{
        margin-top:100px
      }
.navbar{
  background: linear-gradient(0deg, #13243C, #13243C),
  linear-gradient(0deg, #226086, #226086);
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
/* padding margin */
.cstmmargin{
  margin-left: 1rem;
  margin-right: 1rem;
} 
.castimg{
  margin-top: 15px;
  width: 25px;
}
.pt-8{
  padding-top: 8rem;
}
.margb10{
  margin-bottom: 8rem;
}
.margb3{
  margin-bottom: 3rem;
}
.margtbaauto{
  margin-top: auto;
  margin-bottom: auto;
}
.mgtp10{
  margin-top: 10px;
}
.padtb15{
  padding-top: 13rem;
  padding-bottom: 10rem;
}
.padtb5{
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.pdlr0{padding-left: 0px;padding-right: 0px;}
.pdl0{padding-left: 0px;}
.pdr0{padding-right: 0px;}

/* padding margin */
/* navbar */
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.bgsecond{
  background-color: #1E314D;
  color: #fff;
}
.bgfreetutorials{
  background-color: #17273F;
}
.bgconctform{
  background-color: #226086;
}
.tcwhitelink{
  color: #fff;
  text-decoration: underline;
}
.tcwhite{
  color: #fff;
}
.text-center{
  text-align: center;
}
ul{
  color: #fff;
    font-size: 18px;
    line-height: 30px;
}
/* code css */
 .code h1 {
  font-family: 'open_sans_condensedbold';
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}
.tutorial h2 {
  font-size: 32px;
  line-height: 60px;
  font-family: 'Poppins Regular';
  color: #fff;
  text-align: left;
}
.code h3{
  font-size: 28px;
  line-height: 60px;
  font-family: 'Poppins Regular';
  color: #fff;
  text-align: left;
}
.code h4{
  font-size: 22px;
  line-height: 40px;
  font-family: 'Poppins Regular';
  color: #fff;
  text-align: left;
}
pre {
  background-color: #2d2d2d;
  color: #d4d4d4;
  padding: 15px;
  border: 1px solid #444;
  border-radius: 5px;
  overflow: auto;
  text-align: left; /* Ensure left alignment */
}
.code table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}
.code th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  color:#fff
}
.code th {
  /* background-color: #f2f2f2; */
}


/* Style for line numbers */
.line-numbers {
  display: inline-block;
  text-align: right;
  user-select: none;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #444;
}
pre[class*="language-"] { 
  color: #f8f8f2; 
  background: none; 
  text-shadow: 0 1px rgba(0,0,0,.3); 
  font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace; 
  font-size: 1em; 
  text-align: left; 
  white-space: pre; 
  word-spacing: normal; 
  word-break: normal; 
  word-wrap: normal; 
  line-height: 1.5; 
  -moz-tab-size: 4; 
  -o-tab-size: 4; 
  tab-size: 4; 
  -webkit-hyphens: none; 
  -ms-hyphens: none; 
  hyphens: none;
} 

pre[class*="language-"] { 
  padding: 1em; 
  margin: .5em 0; 
  overflow: auto; 
  border-radius: .3em;
} 

pre[class*="language-"] { 
  background: #272822;
} 

*,:after,:before { 
  box-sizing: border-box; 
  border: 0 solid #e5e7eb;
} 

:-webkit-scrollbar { 
  width: 10px; 
  height: 10px;
} 

:-webkit-scrollbar-thumb { 
  background: #d8d4d4; 
  border-radius: 10px; 
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
} 

:-webkit-scrollbar-thumb:hover { 
  background: #a9a9a9;
} 

:-webkit-scrollbar-track { 
  border-radius: 10px; 
  box-shadow: inset 0 0 6px rgba(0,0,0,.1);
} 

code { 
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace; 
  font-size: 1em;
} 

code[class*="language-"] { 
  color: #eab356; 
  background: none; 
  text-shadow: 0 1px rgba(0,0,0,.3); 
  font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace; 
  font-size: 1em; 
  text-align: left; 
  white-space: pre-line; 
  word-spacing: normal; 
  word-break: normal; 
  word-wrap: normal; 
  line-height: 1.5; 
  -moz-tab-size: 4; 
  -o-tab-size: 4; 
  tab-size: 4; 
  -webkit-hyphens: none; 
  -ms-hyphens: none; 
  hyphens: none;
} 

/* Syntax highlighting (example: JavaScript) */
.token.keyword {
  color: #569cd6;
}

.token.function {
  color: #c5a5c5;
}

.token.comment {
  color: #6a9955;
}
.token.doctype{
  color: red;
}
/* code css */
/* contact form*/
.contbox{
  padding: 50px;
  border-radius: 50px 0px 0px 50px;
}
.conrghbox{
  border-radius: 0px 50px 50px 0px;
}
.tph{ 
    font-size: 40px;
    color: #FFFFFFA6;
    font-family: 'Poppins Regular';
}
label{
  width: 100%;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 2.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #172E4F;
  background-clip: padding-box;
  border: 0px solid #ffff;
  border-radius: 0.75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
::placeholder {
  color: #fff !important;
}
.form-group {
  margin-bottom: 2rem;
}
.boxoveform {
  /* box-shadow: 2px -17px 8px 0px #183052; */
  border-radius: 50%;
  margin-top: -21px;
}
.footpara{
  color: #fff;
    border-top: 1px solid #fff;
    padding: 30px;
    text-align: center;
    margin-top: 80px;

} 
.latestpost .latestbox {
  color: #183052;
  background-color: #fff;
  padding: 0px;
  border-radius: 50px;
  border: 5px solid;
  list-style-type: none;
}
.latestbox img{
  border-radius: 30px 30px 0px 0px;
}
.latestbox h3{
  font-family: 'Poppins Regular';
  font-weight: 700;
    font-size: 20px;
}
.latestbox p{ 
  font-size: 16px;
  font-family: 'Poppins Regular';
}
.boxparalatest{
  padding:20px
}
.latestbox a{
  color:#183052;
  text-decoration: none; ;
} 
/* tutorial css start */
.tbox{
  background-color: #13243C;
}
.css-1o0wxxh >.ps-menu-button:active {
  background-color: #f3f3f3 !important;
} 
.boxpadding {
  padding: 13px 0px;
  width: 100%;
  background-color: #D05340;
} 

.tutorial h5{
  padding: 10px;
}
.tutorial .orgbtn{
  background: #D05340;
  color: #fff;
}
.css-h3gd78.ps-broken {
  position: relative !important; 
}
.css-h3gd78.ps-collapsed {
  width: 0px !important;
  min-width: 0px !important;
}
.sbbtn{display: none;}
.tutorial .css-h3gd78{
  border: none;
}
.tutorial .pdlr5{
  padding-left: 15px;
  padding-right: 15px;
}
.tutorial p{
  color: #fff;
}
@media(min-width:768px){
  .css-dip3t8 {
    position: fixed !important;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    background-color: rgb(249, 249, 249, 0.7);
    width: 250px;
    max-width: 250px;
  }
}
/* tutorial css end */
@media(min-width:1024px) and (max-width:1339px){
  h2 {
    font-family: 'open_sans_condensedbold';
    font-size: 120px;
    font-weight: 700;
    line-height: 142px;
    letter-spacing: -0.01em;
    text-align: right;
    color: #FFFFFFA6;
}
.custf {
  font-family: 'open_sans_condensedbold';
  font-size: 140px;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 120px;
  transform: rotate(180deg);
  margin: 0px;
  writing-mode: vertical-lr;
  color: #FFFFFFA6;
}
  .technicalseo {
    font-family: 'Sedgwick Ave Display';
    font-weight: normal;
    font-style: normal;
    font-size: 44px;
    line-height: 45px;
    text-align: center;
    color: #fff;
}
  .navbar-dark .navbar-nav .nav-link {
    color: #fff;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

h1 {
  font-family: 'open_sans_condensedbold';
  color: #226086;
  font-size: 145px;
  font-weight: 700;
  line-height: 130px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}
.cstmmargin {
  margin-left: 1rem;
  margin-right: 0rem;
}
}
 @media(max-width:768px){
  h1 {
    font-family: 'open_sans_condensedbold';
    color: #226086;
    font-size: 130px;
    font-weight: 700;
    line-height: 107px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}
.technicalseo {
  font-family: 'Sedgwick Ave Display';
  font-weight: normal;
  font-style: normal;
  font-size: 34px;
  line-height: 45px;
  text-align: center;
  color: #fff;
}
.col-md-5 {
  flex: 0 0 49.666667%;
  max-width: 49.666667%;
}
.cstmmargin {
  margin-left: 0rem;
  margin-right: 1rem;
}
h2 {
  font-family: 'open_sans_condensedbold';
  font-size: 100px;
  font-weight: 700;
  line-height: 102px;
  letter-spacing: -0.01em;
  text-align: right;
  color: #FFFFFFA6;
}
.col-sm-2 {
  flex: 0 0 16.666667% !important;
  max-width: 16.666667% !important;
}
.tutorial-left h3{
  font-size: 16px;
}
.btn-white {
  padding: 12px, 24px, 12px, 24px !important;
  border-radius: 8px;
  border: 2px solid #fff;
  gap: 10px;
  color: #fff;
  margin-top: 0px;
  font-size: 12px;
}
.tutorial-right {
  position: absolute;
  top: 20%;
  right: 20px;
  text-align: right;
}
.pt-8 {
  padding-top: 3rem;
}
 }
@media(max-width:767px){
  .navbelowmg {
    margin-top: 30px;
}
.css-h3gd78{
  width: 320px !important;
}
  .code h1 {
    font-family: 'open_sans_condensedbold';
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}
.tutorial p {
  color: #fff;
  font-size: 15px;
  line-height: 30px;
}
ul {
  color: #fff;
  font-size: 15px;
  line-height: 30px;
}
.code h3 {
  font-size: 24px;
  line-height: 39px;
  font-family: 'Poppins Regular';
  color: #fff;
  text-align: left;
}
.code h4 {
  font-size: 19px;
  line-height: 30px;
  font-family: 'Poppins Regular';
  color: #fff;
  text-align: left;
}
pre[class*="language-"]{
  font-size: 12px;
}
  .tutorial h2 {
    font-size: 28px;
    line-height: 35px;
    font-family: 'Poppins Regular';
    color: #fff;
    text-align: left;
}
.sbbtn {
  display: block;
  background-color: #d05340;
  color: #fff;
  padding: 5px 15px;
}
  .ssecph{
    position: absolute;
    z-index: 5555;
  }
  .boxoveform {
    box-shadow: 2px -17px 8px 0px #183052;
    border-radius: 50%;
    margin-top: -10px;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
  .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
  .cstmmargin {
    margin-left: 5px;
    margin-right: 5px;
}
  h2 {
    font-family: 'open_sans_condensedbold';
    font-size: 80px;
    font-weight: 700;
    line-height: 82px;
    letter-spacing: -0.01em;
    text-align: right;
    color: #FFFFFFA6;
}
h1 {
  font-family: 'open_sans_condensedbold';
  color: #226086;
  font-size: 96px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}
.custf {
  font-family: 'open_sans_condensedbold';
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 40px;
  transform: rotate(180deg);
  margin: 0px;
  writing-mode: vertical-lr;
  color: #FFFFFFA6;
}
.mbpdt0{
  padding-top: 0rem !important;
}
.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: transparent;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: space-between;
}
.mbmbd0{
  margin-bottom: 0em;
}
/* mobile padding left right zero */
.mbbpdlr0{
  padding-left: 0px;
  padding-right: 0px;
}
.mbbpdl0{
  padding-left: 0px;
}
.aboutme p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 13px;
}
.mt-4{
  margin-top: 0.1rem!important;
}
.mbtext-center{
  text-align: center;
}
.mbbpadtb3{
padding-top: 3rem;
padding-bottom: 3rem;
}
.tutorial-left {
  position: absolute;
  top: 50px;
  left: 15px;
  padding: 30px;
}
.tutorial-left h3 {
  font-size: 28px;
}
h2 {
  font-family: 'open_sans_condensedbold';
  font-size: 52px;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: -0.01em;
  text-align: right;
  color: #FFFFFFA6;
}
.tutorial-right {
  position: absolute;
  top: 35%;
  right: 40px;
  text-align: right;
}
.conrghbox {
  border-radius: 10px 10px 0px 0px;
}
.contbox {
  padding: 50px;
  border-radius: 0px 0px 50px 50px;
}
}

@media(max-width:320px)
{
  .tutorial-left h3 {
    font-size: 22px;
}
  .custf {
    font-family: 'open_sans_condensedbold';
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0em;
    line-height: 25px;
    transform: rotate(180deg);
    margin: 0px;
    writing-mode: vertical-lr;
    color: #FFFFFFA6;
}
h2 {
  font-family: 'open_sans_condensedbold';
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.01em;
  text-align: right;
  color: #FFFFFFA6;
}
}